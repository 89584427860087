<script>
import { count, GET_userrecords, simpleconfig } from '@/api/beijing28'

export default {
    name: 'YuLiBao',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header:{
                mainTitle:getWord('yulibao'),
            },
            count:null,
            list:null,            
            profitList:null,
        }
    },
    mounted() {
        var that=this;
        this.$store.dispatch('GET_yulibaoConfig',{});
        count().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.count=result.data.result;
                app.$store.commit('UPDATE_yulibaoInfo',result.data.result);

                GET_userrecords().then(result=>{
                    that.list=result.data.result.list;
                    that.profitList=that.list.filter(item=>{
                        return item.type===3
                    })
                })
            }else {
                app.Mint.Toast(result.data.msg);
            }
        })
    },
    computed: {
        config(){
            return this.$store.state.yulibaoConfig;
        }
    },
    methods: {
        filterNumber(value){
            var number=value.split('.')[1].split('');
            var result='';
            number.forEach((item,index)=>{
                if (index!==2) {
                    result+=number[index]
                }
                else {
                    result=result+'.'+number[index]
                }                    
            })
            result=result-0+''

            return result;
        },
        goto(where){
            this.$router.push({path: where});
        },
        getFloatInsterest(){
            if (this.config.type===2) {                
                return this.filterNumber(this.config.float_value) + '%'
            }else if (this.config.type===3) {
                var result=[];
                var data=JSON.parse(this.config.count_condition);
                data.forEach(item=>{
                    if (item.value) {
                        result.push( this.filterNumber(item.value) )
                    }
                })
                var min=result.sort(function(a,b){
                    return a-b
                })[0]
                var max=result.sort(function(a,b){
                    return b-a
                })[0]
                if (Number(min)===Number(max) && Number(min)===0) {
                    return '0%'
                }else if ( Number(min)===Number(max) ) {
                    return min + "%"
                }else {
                    return min + '%' + '~' + max + '%'
                }
            }else {
                return '0%'
            }
        }
    }
}
</script>
<template>
    <section id='ylb'>
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
            :transparent=true
        >
            <button class="right-menu" slot="right-menu" @click='goto("/my/YuLiBaoDetail")'>{{ getWord('detail') }}</button>
        </Header>
        <div class="info">
            <div class="inner">
                <h3>
                    <span class="sub-title">{{ getWord('total4') }}({{ currencySymbol() }})</span>
                    <span class="number">{{count&&count.total_money || '0.00'}}</span>
                    <span class="other">{{ getWord(['yesterday2', 'income2']) }} <i>{{ this.profitList&&this.profitList[0]?this.profitList[0].change_money:'0.00' }}</i> {{ currencySymbol() }}</span>
                </h3>
                <div class="summary" v-if="config">
                    <table>
                        <tbody>
                            <tr>
                                <th colspan="2">{{ getWord('income3') }}({{ currencySymbol() }})</th>
                            </tr>
                            <tr>
                                <td colspan="2">{{ count&&count.total_insterest || '0.00' }}</td>
                            </tr>
                            <tr>                            
                                <th>{{ getWord('base_yield_rates') }}(%)</th>
                                <th>{{ getWord('yield_rates2') }}(%)</th>
                            </tr>
                            <tr>                            
                                <td>{{ config && (config.value?filterNumber(config.value):'0%') }}%</td>
                                <td>{{ getFloatInsterest() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="buttons">
                    <button @click='goto("/my/YuLiBaoTransfer?type=out")'>{{ getWord('transfer_out') }}</button>
                    <button @click='goto("/my/YuLiBaoTransfer?type=in")' :class="{'hidden':config?config.status!==1:false}">{{ getWord('transfer_in') }}</button>
                </div>
            </div>
        </div>
        <div class="tips">
            <p>{{ getWord('tips2') }}: <br>{{ getWord('base_yield_rates_explain') }} <br>{{ getWord('yield_rates2_explain2',{
                '$1':'1',
                '$2':'0.01',
            }) }}</p>
            <p>{{ getWord(['income2', 'time']) }}： <br>1.{{ getWord('yulibao_income_explain') }} <br>2.{{ getWord('yulibao_income_explain2') }} <br>3.{{ getWord('yulibao_income_explain3') }}</p>
            <p>{{ getWord('example') }}： {{ getWord('yulibao_income_explain4') }}{{ getWord('yulibao_income_explain5') }}，{{ getWord('yulibao_income_explain6') }}</p>
        </div>
    </section>
</template>

<style lang='scss' scoped>
#ylb {
    overflow: auto;

    /deep/ header {
        background: #fe6601;
    }

    .bg {
        height: 6.27rem;
        background:linear-gradient(180deg,rgba(254,102,1,1) 0%,rgba(255,232,218,1) 100%);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .right-menu {
        position: absolute;
        right: 0.15rem;
        top: 0;
        height: 100%;
        background: 0;
        border: 0;
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 500;
    }

    .info {
        padding: .3rem .25rem;
        background: linear-gradient(180deg,#fe6601,#ffffff 80%);

        .inner {
            margin: .25rem;
            background-color: #ffffff;
            border-radius: .25rem;
            padding: .3rem .25rem;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
        }

        h3 {
            text-align: center;
            padding: .25rem 0;
            font-weight: normal;

            span {
                display: block;
                margin: .1rem 0;

                &.sub-title {
                    font-size: .26rem;
                }

                &.number {
                    font-size: .68rem;
                }

                &.other {
                    display: inline-block;
                    font-size: .26rem;
                    background-color: #F5F5F9;
                    padding: .1rem .25rem;
                    border-radius: .25rem;

                    i {
                        font-style: normal;
                        color: #FE6601;
                    }
                }
            }
        }

        .summary {

            table {
                width:100%;

                tbody {

                    tr {

                        th {
                            color: #CCCCCC;
                            font-size: .26rem;
                            font-weight: normal;
                        }

                        td {
                            font-size: .36rem;
                            text-align: center;
                            padding: .1rem 0;
                        }
                    }
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: .25rem;

            button {
                flex: 0 1 48%;
                font-size: .36rem;
                background-color: #FEE6D8;
                color: #FE7419;
                border: 0;
                padding: .25rem 0;
                border-radius: .1rem;

                &:last-child {
                    background-color: #FE7419;
                    color: #ffffff;
                }
            }
        }
    }

    .tips {
        padding: 0 .5rem;

        p {
            font-size: .3rem;
            color: #999999;
            margin-bottom: .25rem;
        }
    }
}
</style>
